import * as React from "react"
import {  Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Slider from 'react-slick';
import ContactCard from "../Home/ContactCard/ContactCard"

import "../FeaturedNewHome/FeaturedNewHome.scss"
import { NewProjects } from "../../queries/common_use_query"
import { addCommas} from "../common/utils"
import {externalURL} from "../common/utils"

const FeaturedNewHome = (props) => {

 const featuredetails = props?.featured_details
 const { loading, error, data } = NewProjects(props.pstatus, props.region, props.sort, 10);

 const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    mobileFirst:true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint:1199,
         settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true
            }
    },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true
              }
      },
     
  ]
}
//console.log("data",featuredetails)
  return (
    featuredetails?.Show_New_Projects_Qatar === true &&
        <div className="featured-new-projects-section market-analysis project-analysis">
            <Container className="featured-new-container">
              <h2 className="new-project-title text-center">{featuredetails?.New_Project_Title}</h2>
          
              <div className="featured-new-projects-slider-section">

                           <Slider {...settings} >
                                                        {
                                                            data && data.newProjects.length > 0 && data.newProjects.map((item, index) => {
                                                                var url = "/buy-new-projects-in-qatar";
                                                                var tag = "";

                                                                var urlLink = `${url}/${item.URL}`;
                                                                return (
                                                                
                                                                    <ContactCard
                                                                        cardImg={item?.Title_Image}
                                                                        cardTitle={item.Name}
                                                                        date={`${addCommas(item.Price_Range)}`}
                                                                        cardTag={tag}
                                                                        imagetransforms={item.imagetransforms}
                                                                        cardUrl={urlLink}
                                                                    />
                                                                )
                                                            })
                                                        }
                             </Slider>    
                </div>
                <div className="text-center view-more-cta">
                        <Link to={featuredetails?.New_Project_CTA_Link} target={externalURL(featuredetails?.New_Project_CTA_Link) ? "_blank" : ""} className="btn btn-primary">
                                        <span>{featuredetails.New_Project_CTA_Label}</span>
                        </Link>
                </div>
            </Container>   
         </div>
      
   
  )
}

export default FeaturedNewHome